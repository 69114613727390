export const ProtectionCards =[
    {
        id:'olympus-protection-id1',
        xmlns: 'http://www.w3.org/2000/svg',
        fill: 'currentColor',
        className: 'bi bi-shield-minus',
        viewBox: '0 0 16 16',
        path1:'M5.338 1.59a61.44 61.44 0 0 0-2.837.856.481.481 0 0 0-.328.39c-.554 4.157.726 7.19 2.253 9.188a10.725 10.725 0 0 0 2.287 2.233c.346.244.652.42.893.533.12.057.218.095.293.118a.55.55 0 0 0 .101.025.615.615 0 0 0 .1-.025c.076-.023.174-.061.294-.118.24-.113.547-.29.893-.533a10.726 10.726 0 0 0 2.287-2.233c1.527-1.997 2.807-5.031 2.253-9.188a.48.48 0 0 0-.328-.39c-.651-.213-1.75-.56-2.837-.855C9.552 1.29 8.531 1.067 8 1.067c-.53 0-1.552.223-2.662.524zM5.072.56C6.157.265 7.31 0 8 0s1.843.265 2.928.56c1.11.3 2.229.655 2.887.87a1.54 1.54 0 0 1 1.044 1.262c.596 4.477-.787 7.795-2.465 9.99a11.775 11.775 0 0 1-2.517 2.453 7.159 7.159 0 0 1-1.048.625c-.28.132-.581.24-.829.24s-.548-.108-.829-.24a7.158 7.158 0 0 1-1.048-.625 11.777 11.777 0 0 1-2.517-2.453C1.928 10.487.545 7.169 1.141 2.692A1.54 1.54 0 0 1 2.185 1.43 62.456 62.456 0 0 1 5.072.56z',
        path2:'M5.5 7a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1H6a.5.5 0 0 1-.5-.5z',
        title:'Local Network Attacks',
        description:'No Matter The Setup, We Can Test Every Device On Your Network, Even Printers And Cameras, For The Most Hidden Vulnerabilities And Possible Active Exploitations.',
    },
    {
        id:'olympus-protection-id2',
        xmlns: 'http://www.w3.org/2000/svg',
        fill: 'currentColor',
        className: 'bi bi-shield-plus',
        viewBox: '0 0 16 16',
        path1:'M5.338 1.59a61.44 61.44 0 0 0-2.837.856.481.481 0 0 0-.328.39c-.554 4.157.726 7.19 2.253 9.188a10.725 10.725 0 0 0 2.287 2.233c.346.244.652.42.893.533.12.057.218.095.293.118a.55.55 0 0 0 .101.025.615.615 0 0 0 .1-.025c.076-.023.174-.061.294-.118.24-.113.547-.29.893-.533a10.726 10.726 0 0 0 2.287-2.233c1.527-1.997 2.807-5.031 2.253-9.188a.48.48 0 0 0-.328-.39c-.651-.213-1.75-.56-2.837-.855C9.552 1.29 8.531 1.067 8 1.067c-.53 0-1.552.223-2.662.524zM5.072.56C6.157.265 7.31 0 8 0s1.843.265 2.928.56c1.11.3 2.229.655 2.887.87a1.54 1.54 0 0 1 1.044 1.262c.596 4.477-.787 7.795-2.465 9.99a11.775 11.775 0 0 1-2.517 2.453 7.159 7.159 0 0 1-1.048.625c-.28.132-.581.24-.829.24s-.548-.108-.829-.24a7.158 7.158 0 0 1-1.048-.625 11.777 11.777 0 0 1-2.517-2.453C1.928 10.487.545 7.169 1.141 2.692A1.54 1.54 0 0 1 2.185 1.43 62.456 62.456 0 0 1 5.072.56z',
        path2:'M8 4.5a.5.5 0 0 1 .5.5v1.5H10a.5.5 0 0 1 0 1H8.5V9a.5.5 0 0 1-1 0V7.5H6a.5.5 0 0 1 0-1h1.5V5a.5.5 0 0 1 .5-.5z',
        title:'Remote/Cyber Attacks',
        description:'Layer4 And Layer7 Offensive Penetration Test Against Web Applications, Mobile Applications, Hosting Servers, Databases, Emails, Public IPs, WAF Bypass And Much More...',
    },
    {
        id:'olympus-protection-id3',
        xmlns: 'http://www.w3.org/2000/svg',
        fill: 'currentColor',
        className: 'bi bi-lock',
        viewBox: '0 0 16 16',
        path1:'M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2zM5 8h6a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1z',
        path2:'',
        title:'RansomWare',
        description:'Olympus Shield Is The Only Cyber Security Firm In Lebanon Yet To Have Legally Registered The Intellectual Property Of The First Ever Effective Anti-Ransomware Solution. ',
    },
    {
        id:'olympus-protection-id4',
        xmlns: 'http://www.w3.org/2000/svg',
        fill: 'currentColor',
        className: 'bi bi-cloud-fog2',
        viewBox: '0 0 16 16',
        path1:'M8.5 4a4.002 4.002 0 0 0-3.8 2.745.5.5 0 1 1-.949-.313 5.002 5.002 0 0 1 9.654.595A3 3 0 0 1 13 13H.5a.5.5 0 0 1 0-1H13a2 2 0 0 0 .001-4h-.026a.5.5 0 0 1-.5-.445A4 4 0 0 0 8.5 4zM0 8.5A.5.5 0 0 1 .5 8h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5z',
        path2:'',
        title:'Black SEO & Identity Theft',
        description:'We Investigate The Source Of Any Black SEO Campaign And Totally Wipe It Off The Internet To Protect Your Reputation, Your Privacy, And The Flow Of Your Business Operations.',
    },
];